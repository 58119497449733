.titolo {
    font-size: 30px;
}
.body {
    height: 500px;
    width: 900px;
    background-color: rgba(255,255,255,0.13);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    /*padding: 50px 35px;*/
    padding: 20px;
    font-size: 40px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.body *{
    font-family: 'Poppins',sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}
.body h3{
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgba(90, 90, 90);
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

@media screen and (max-width:950px) {
    .body {
        width: 90%;
    }
}